import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  NgForm,
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { Answers } from 'src/app/models/lookups/Answers';
@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
})
export class QuestionnaireComponent implements OnInit {
  @Output() AnswerQues = new EventEmitter<{}>();
  @Output() validAnswer = new EventEmitter<{}>();
  @Input() isSubmited = false;
  @Input() isRequired = false;
  @Input() oneQustion: any;
  @Input() answerListing!: any[];
  oneAnswer = {} as Answers;

  textareaControl = new FormControl('', [Validators.required]);
  numberControl = new FormControl('', [Validators.required]);
  textControl = new FormControl('', [Validators.required]);
  dateControl = new FormControl(null, [Validators.required]);
  timeControl = new FormControl('', [Validators.required]);
  onechoiceControl = new FormControl('', [Validators.required]);
  otherQuesControl = new FormControl('', [Validators.required]);

  // multi choice
  checkedItems: any[] = [];
  noChecked = false;
  otherChecked = false;
  // rating
  activeStar = false;

  constructor() {}

  ngOnInit() {
    if (
      this.oneQustion &&
      this.oneQustion.options &&
      this.oneQustion.options.length &&
      this.oneQustion.questionType.code == 1
    ) {
      this.oneQustion.options.map((option: any) => {
        option.ischecked = false;
      });
    }
    // console.log('oneQustion', this.oneQustion);
    // console.log('answerListing', this.answerListing);
    if (this.answerListing && this.answerListing.length) {
      this.bindData();
    }
  }

  bindData() {
    this.answerListing.forEach((answer) => {
      if (
        answer.questionnaireQuestionId ==
        this.oneQustion.questionnaireQuestionId
      ) {
        console.log('answer', answer);
        if (answer.questionTypeCode == 1) {
          this.otherQuesControl.setValue(answer.text);
          this.oneQustion.options.forEach((option: any) => {
            if (answer.answersIds && answer.answersIds.length) {
              answer.answersIds.forEach((answersId: any) => {
                if (option.id == answersId.questionValueVersionId) {
                  option.ischecked = true;
                  this.otherQuesControl.setValue(answersId.text);
                  this.changeStatus(this.oneQustion, option, option.ischecked);
                }
              });
            }
          });
        }
        console.log('oneQustion', this.oneQustion);

        if (answer.questionTypeCode == 2) {
          if (answer.answersIds && answer.answersIds.length) {
            this.oneQustion.options.forEach((option: any) => {
              if (answer.answersIds && answer.answersIds.length) {
                answer.answersIds.forEach((answersId: any) => {
                  if (option.id == answersId.questionValueVersionId) {
                    this.onechoiceControl.setValue(
                      answersId.questionValueVersionId
                    );
                    this.otherQuesControl.setValue(answersId.text);
                    this.radioListChange(this.oneQustion, option, null);
                  }
                });
              }
            });
          }
        }
        if (answer.questionTypeCode == 3) {
          this.textareaControl.setValue(answer.text);
          this.setAnswer(this.textareaControl, this.oneQustion);
        }
        if (answer.questionTypeCode == 4) {
          this.numberControl.setValue(answer.text);
          this.setAnswer(this.numberControl, this.oneQustion);
        }
        if (answer.questionTypeCode == 5) {
          this.textControl.setValue(answer.text);
          this.setAnswer(this.textControl, this.oneQustion);
        }
        if (answer.questionTypeCode == 6) {
          this.dateControl.setValue(new Date(answer.text));
          this.setAnswer(this.dateControl, this.oneQustion);
        }
        if (answer.questionTypeCode == 7) {
          this.timeControl.setValue(answer.text);
          this.setAnswer(this.timeControl, this.oneQustion);
        }
      }
    });
  }

  checkOtherValue() {
    if (this.otherQuesControl.value) {
      if (this.oneAnswer.answersIds && this.oneAnswer.answersIds.length) {
        const otherOption = this.oneQustion.options.find(
          (option: any) => option.isOtherAnswer == true
        );
        if (otherOption) {
          this.oneAnswer.answersIds.forEach((answersId) => {
            if (answersId.questionValueVersionId == otherOption.id) {
              answersId.text = this.otherQuesControl.value;
            }
          });
        }
      }
      this.AnswerQues.emit(this.oneAnswer);
      this.validAnswer.emit(true);
    } else {
      this.validAnswer.emit(false);
    }
  }

  changeStatus(question: any, option: any, event: any) {
    // this.otherChecked = this.oneQustion.allowOtherAnswer;

    this.oneAnswer.questionnaireQuestionId = question.questionnaireQuestionId;
    this.oneAnswer.questionTypeCode = question.questionType.code;

    // isother
    if (option.isOtherAnswer) {
      this.otherChecked = !this.otherChecked;
    }

    if (event.checked || event == true) {
      option.ischecked = true;
      this.checkedItems.push({
        questionValueVersionId: option.id,
        isOtherAnswer: option.isOtherAnswer,
      });
      if (!option.isOtherAnswer) {
        this.oneAnswer.text = '';
        this.oneAnswer.answersIds = this.checkedItems;
        this.validAnswer.emit(true);
        this.AnswerQues.emit(this.oneAnswer);
      }
      if (option.isOtherAnswer) {
        this.oneAnswer.answersIds = this.checkedItems;
        this.checkOtherValue();
      }
    } else {
      option.ischecked = false;
      this.checkedItems = this.checkedItems.filter((item) => {
        return item.questionValueVersionId !== option.id;
      });
      if (this.checkedItems.length) {
        this.validAnswer.emit(true);
      }
      this.oneAnswer.answersIds = this.checkedItems;
      // isother
      if (option.isOtherAnswer) {
        this.otherQuesControl.setValue('');
      }

      this.AnswerQues.emit(this.oneAnswer);
    }

    // error msg
    if (!this.checkedItems.length) {
      this.noChecked = true;
    }

    // || (option.isOtherAnswer && this.otherQuesControl.value === '')
    if (!this.checkedItems.length) {
      this.validAnswer.emit(false);
    }
    // console.log(this.checkedItems);
  }

  setAnswer(control: any, question: any) {
    if (control.value) {
      this.oneAnswer.questionnaireQuestionId = question.questionnaireQuestionId;
      this.oneAnswer.questionTypeCode = question.questionType.code;
      if (this.oneAnswer.questionTypeCode == 6) {
        this.oneAnswer.text = this.formatDate(control.value);
      } else {
        this.oneAnswer.text = control.value;
      }
      this.AnswerQues.emit(this.oneAnswer);
      this.validAnswer.emit(true);
    } else {
      this.validAnswer.emit(false);
    }
  }

  formatDate(date: any) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [month, day, year].join('-');
  }

  settimeAnswer(controname: any, id: any) {
    if (controname == 'timeControl') {
      this.oneAnswer.questionnaireQuestionId = id;
      this.oneAnswer.text = this.timeControl.value;
      this.AnswerQues.emit(this.oneAnswer);
      this.validAnswer.emit(true);
    } else {
      this.validAnswer.emit(false);
    }
  }

  radioListChange(question: any, option: any, event: any) {
    this.otherChecked = option.isOtherAnswer; // show / hide others textarea
    // this.otherChecked = this.oneQustion.allowOtherAnswer;

    this.oneAnswer.questionnaireQuestionId = question.questionnaireQuestionId;
    this.oneAnswer.answersIds = [];
    this.oneAnswer.answersIds.push({
      questionValueVersionId: option.id,
      isOtherAnswer: option.isOtherAnswer,
    });
    this.oneAnswer.questionTypeCode = question.questionType.code;
    if (!option.isOtherAnswer) {
      this.oneAnswer.text = '';
      this.otherQuesControl.setValue('');
      this.AnswerQues.emit(this.oneAnswer);
      this.validAnswer.emit(true);
    }
    if (option.isOtherAnswer) {
      this.checkOtherValue();
    }

    if (option.isOtherAnswer && this.otherQuesControl.value === '') {
      this.validAnswer.emit(false);
    }
  }
}
