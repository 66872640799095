<mat-dialog-content class="createUserDialog">
  <section class="createUser">

    <div *ngIf='dataLoading' class="dataLoading">
      <mat-spinner class="m-auto" diameter="50"></mat-spinner>
    </div>

    <div class="auth-card pb-0" *ngIf='!dataLoading'>

      <div class="d-flex align-items-center justify-content-between">
        <div>
          <h2 class="title">{{ 'requestes.Upload Captured Image' | translate}}</h2>
        </div>

        <button class="close" (click)="closeDialog()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div>
      <form class="form-ds upload-form" [formGroup]="formGroup">
        <div class="scroll-div p-3">
          <mat-form-field appearance="fill">
            <mat-label>Title</mat-label>
            <input matInput formControlName="title" maxlength="200">
            <mat-error *ngIf="hasError(formGroup, 'title', 'required')">{{ "forms.Required" | translate
              }}
            </mat-error>
          </mat-form-field>
          <p class="mt-1">{{ 'requestes.If you uploaded' | translate}}</p>
          <h6>{{ 'requestes.Image' | translate}}</h6>
          <app-file-manager [isRequired]='true' [maxFiles]='1'
            [allowedExt]="['png', 'jpeg', 'jpg']" [isSubmited]='isSubmited' [files]="files"
            (selectedFiles)="updateFilesField($event)" (deletedFilesList)='deletedFiles = $event'>
          </app-file-manager>
        </div>
          <div class="btn-container mx-3">
            <button mat-raised-button color="grey" mat-dialog-close type="button">{{
              'swal.cancel' | translate }}</button>

            <button mat-raised-button color="primary" (click)="save()" [class.loading-btn]="loading" [disabled]="loading">
              {{ 'requestes.Upload & Continue to Viewer' | translate }}
            </button>
          </div>
      </form>
    </div>
  </section>
</mat-dialog-content>
