import { checkErrorsDialogComponent } from './check-errors-dialog/check-errors-dialog.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ContactInfoFormComponent } from './contact-info-form/contact-info-form.component';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { FilesViewerComponent } from './files-viewer/files-viewer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DemoMaterialModule } from '../helpers/material-modules';
import { TranslateModule } from '@ngx-translate/core';

import { ShortNamePipe } from '../helpers/shortName.pipe';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { DndDirective } from '../helpers/dnd.directive';
import { OnlynumberDirective } from '../helpers/onlynumber.directive';
import { SanitizeHtmlPipePipe } from '../helpers/sanitize-html-pipe.pipe';
import {
  RecaptchaModule,
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
} from 'ng-recaptcha';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { TreeSelectionComponent } from './tree-selection/tree-selection.component';
import { ChipsTreeSearchComponent } from './chips-tree-search/chips-tree-search.component';
import { NestedMaterialTreeComponent } from './nested-material-tree/nested-material-tree.component';
import { KpiComponent } from './kpi/kpi.component';
import { KpiNewComponent } from './kpi-new/kpi-new.component';
import { UploadFormComponent } from './upload-form/upload-form.component';
import { AssistanceRequestQuestionnaireComponent } from './assistance-request-questionnaire/assistance-request-questionnaire.component';
import { OtherQuestionsComponent } from './other-questions/other-questions.component';
import { FillOutApplicationComponent } from './fill-out-application/fill-out-application.component';
import { ApplicationKpisComponent } from './application-kpis/application-kpis.component';
import { environment } from 'src/environments/environment';
import { DateUTCPipe } from '../helpers/MomentPipe.pipe';
import { ContactInfoReactiveFormComponent } from './contact-info-reactive-form/contact-info-reactive-form.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [
    DndDirective,
    ShortNamePipe,
    OnlynumberDirective,
    SanitizeHtmlPipePipe,
    QuestionnaireComponent,
    FilesViewerComponent,
    FileManagerComponent,
    ContactInfoFormComponent,
    TreeSelectionComponent,
    ChipsTreeSearchComponent,
    NestedMaterialTreeComponent,
    KpiComponent,
    KpiNewComponent,
    UploadFormComponent,
    AssistanceRequestQuestionnaireComponent,
    OtherQuestionsComponent,
    FillOutApplicationComponent,
    ApplicationKpisComponent,
    DateUTCPipe,
    ContactInfoReactiveFormComponent,
    checkErrorsDialogComponent
  ],
  imports: [
    CommonModule,
    // Forms
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    InfiniteScrollModule,
    // Recaptcha
    RecaptchaModule,
    // material
    DemoMaterialModule,
    NgxMaterialTimepickerModule,
    TranslateModule.forChild({}),
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaModule,
    DemoMaterialModule,
    NgxMaterialTimepickerModule,
    TranslateModule,
    NgxIntlTelInputModule,
    InfiniteScrollModule,
    // helpers
    DndDirective,
    OnlynumberDirective,
    SanitizeHtmlPipePipe,
    ShortNamePipe,

    // comp
    QuestionnaireComponent,
    KpiComponent,
    FilesViewerComponent,
    FileManagerComponent,
    ContactInfoFormComponent,
    TreeSelectionComponent,
    ChipsTreeSearchComponent,
    NestedMaterialTreeComponent,
    KpiNewComponent,
    AssistanceRequestQuestionnaireComponent,
    OtherQuestionsComponent,
    ApplicationKpisComponent,
    DateUTCPipe,
    ContactInfoReactiveFormComponent,
    checkErrorsDialogComponent
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.googleSiteKey,
        useValue: localStorage.getItem('language') || 'en',
      } as RecaptchaSettings,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: true, disableClose: true },
    },
  ],
})
export class SharedModule { }
