// import { Options } from "./Options";
// import { QuestionType } from "./QuestionType";

export class Kpi {
  id!: number;
  name!: number;
  questions: Question[] = new Array();
}
export class Question {
  mustBeAnswered: boolean;
  text: string;
  options: Options[] = new Array();
  questionTypeCode: number;
  selectAnswer = true;
  ischecked = true;
  applicationKPIQuestionId: number;
  answer:answer[] = new Array();
  valid?:any = false;
  answeredInPeriorRequest:boolean;
  answeredInProject?:string;
  answeringDate?:string;
  kpiQuestionId:number;
}
export class Options {
  questionOptionId: number;
  text: string;
  questions: Question[] = new Array();
  ischecked?: boolean = false;
}
export class AnswersKpi {
  text!: string;
  questionOptionId!: number;
  answerIds!: number[];
  isOtherAnswer!: boolean[];
  answersIds!: any[];
  questionTypeCode!: number;
  answer: answer[] = new Array();
}
export class KpiAnswer {
  kpiQuestionId: number;
  questionTypeCode: number;
  text: string;
  answer:any;
  answersIds: answer[] = new Array();
}
export class answer {
  kpiQuestionValueIds:any = [];
  isOtherAnswer: boolean;
  otherAnswerId?: number;
  text: string;
  ischecked?: boolean = false;
}
