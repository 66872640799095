import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
export interface DialogData {
  id: number;
}
@Component({
  selector: 'app-fill-out-application',
  templateUrl: './fill-out-application.component.html'
})
export class FillOutApplicationComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    private router: Router,
    ) { }

  ngOnInit(): void {
    console.log(this.data);

  }

  requestDetails() {
    this.router.navigate(['/profile/request-details', this.data]);
    this.dialog.closeAll();
   }

}
