import { Injectable, EventEmitter, DebugElement } from '@angular/core';
declare var $: any;
import { AuthenticationService } from './authentication.service';

import * as signalR from '@microsoft/signalr';
import { IHttpConnectionOptions } from '@microsoft/signalr';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignalRServiceService {
  private hubConnection!: signalR.HubConnection;
  isConnectionStarted = false;

  constructor(private authService: AuthenticationService) { }

  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.signalRUrl, {
        skipNegotiation: true,
        transport:
          signalR.HttpTransportType.WebSockets ||
          signalR.HttpTransportType.LongPolling,
        accessTokenFactory: () => this.authService.currentUserValue.accessToken,
      })
      .build();

    this.hubConnection
      .start()
      .then(() => {
        console.log('Connection started ...')
        this.isConnectionStarted = true;
      }
      )
      .catch((err) =>
        console.log('Error while starting connection ... ' + err)
      );
  };

  stopConnection() {
    if (this.hubConnection) {
      this.hubConnection
        .stop()
        .then(() => console.log('Connection stopped ...'))
        .catch((err) =>
          console.log('Error while stopping connection ... ' + err)
        );
    }
  }

  sendAlert = (context: any, handleFunc: any) => {
    this.hubConnection.on('sendAlert', (data) => {
      handleFunc(context, data);
    });
  };
  disposeAlert = (context: any, handleFunc: any) => {
    this.hubConnection.on('disposeAlert', (taskGuid) => {
      handleFunc(context, taskGuid);
    });
  };
  changeNotificationCount = (context: any, handleFunc: any) => {
    this.hubConnection.on('updateNotifications', (count) => {
      handleFunc(context, count);
    });
  };

  handleRequestUpdatedEvent(context: any, handleFunc: any) {
    this.hubConnection.on('requestUpdated', (requestId: any) => {
      handleFunc(context, requestId);
    });
  }
  handleProfileUpdatedEvent(context: any, handleFunc: any) {
    this.hubConnection.on('profileUpdated', (profileId: any) => {
      handleFunc(context, profileId);
    });
  }
  handleNewEnterpriseRequestEvent(context: any, handleFunc: any) {
    this.hubConnection.on('newEnterpriseRequest', () => {
      handleFunc(context);
    });
  }
  handleNewExpertRequestEvent(context: any, handleFunc: any) {
    this.hubConnection.on('newExpertRequest', () => {
      handleFunc(context);
    });
  }
  handleNewMissionRequestUpdated(context: any, handleFunc: any) {
    this.hubConnection.on('missionRequestUpdated', () => {
      handleFunc(context);
    });
  }
  handlekPIsNotifyingEnterprises(context: any, handleFunc: any) {
    this.hubConnection.on('kPIsNotifyingEnterprises', () => {
      handleFunc(context);
    });
  }
  handleWithdraw(context: any, handleFunc: any) {
    this.hubConnection.on('withdraw', (caseId) => {
      handleFunc(context, caseId);
    });
  }
  handleCaseUpdated = (context: any, handleFunc: any) => {

    this.hubConnection?.on('caseUpdated', (requestId) => {
      handleFunc(context, requestId);
    });
  }
  handleCaseUpdatedNew(context: any, handleFunc: any) {
    this.hubConnection?.on('caseUpdated1', (requestId) => {
      handleFunc(context, requestId);
    });
  }
  handlecapturedImageUpdated(context: any, handleFunc: any) {
    this.hubConnection?.on('capturedImageUpdated', (requestId) => {
      handleFunc(context, requestId);
    });
  }

  handleExpertMissionDecision(context: any, handleFunc: any) {
    this.hubConnection.on('expertMissionDecision', (requestId: any) => {
      handleFunc(context, requestId);
    });
  }

  handleFillAppUpdated = (context: any, handleFunc: any) => {
    if (this.hubConnection) {
      this.hubConnection?.on('enterpriseFillAppNotifySignlar', (requestId) => {
        // console.log(context);
        handleFunc(context, requestId);
      });
    }
  }

  handleDeclineAssistanceRequest = (context: any, handleFunc: any) => {
    if (this.hubConnection) {
      this.hubConnection?.on('declineAssistanceRequest', (requestId) => {
        console.log(context);
        handleFunc(context, requestId);
      });
    }
  }

  handleExpertMissionAccepted(context: any, handleFunc: any) {
    this.hubConnection?.on('expertMissionAccepted', (requestId) => {
      handleFunc(context, requestId);
    });
  }

  handleWithdrawCaseFromExpert(context: any, handleFunc: any) {
    this.hubConnection?.on('WithdrawCaseFromExpert', (requestId) => {
      handleFunc(context, requestId);
    });
  }

  logout = (context: any, handleFunc: any) => {
    this.hubConnection.on('logout', () => {
      handleFunc(context);
    });
  };
}
