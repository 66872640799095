import { CommunicationPlatformTypes } from "./enum/CommunicationPlatformTypes";

export class CommunicationType {
  id!: number;
  name!: string;
  communicationPlatformType!: CommunicationPlatformTypes;
  appIcon!: string;
  hint!: string;
  index!: number;
  value!: any;
}
