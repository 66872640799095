import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit {
  isHome = false;
returnUrl:any
  constructor(
    location: Location,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params: Params) => {
      this.returnUrl = params['returnUrl']
    });
    router.events.subscribe((val) => {
      if (location.path() === '/home' || location.path() === '' || this.returnUrl) {
        this.isHome = true;
      }else {
        this.isHome = false
      }
    });
  }

  ngOnInit() {}
}
