<mat-dialog-content class="createUserDialog">
  <section class="createUser">
    <div class="auth-card">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <!-- <h2 class="title">{{ "project.Are you sure you want to update" | translate }}</h2> -->
          <h2 class="title mb-0">{{ "swal.Failed" | translate }}</h2>
        </div>

        <button class="close" mat-dialog-close>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div class="form-element m-4">
      <div class="row dialog-body">
        <div class="col-12">
          <h6></h6>
          <ul class="sector-list">
            <li *ngFor="let message of assignedResources"> {{message}}</li>
          </ul>
        </div>
      </div>
      <div class="row mt-3 text-center">
        <div class="col-12">
          <div class="btn-container mt-3 justify-content-center">
            <button mat-raised-button color="grey" mat-dialog-close> {{"swal.ok" | translate }}</button>
            <!-- <button mat-raised-button class="btn" color="primary" [class.loading-btn]="loading" [disabled]="loading"
            [mat-dialog-close]="isUpdate" cdkFocusInitial>Update</button> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</mat-dialog-content>