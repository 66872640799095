<div class="files-preview-container" [ngClass]="{'active': docsEditable}">
  <div class="dnd-container" [hidden]='!docsEditable' appDnd (fileDropped)="onFileDropped($event)">
    <input type="file" #fileDropRef id="fileDropRef" [multiple]="isMultiple" (change)="fileBrowseHandler($event)"
      accept="image/png, image/jpeg, application/pdf, .doc, .docx, .xlsx, .xls, .zip, .rar" />

    <div>
      <svg width="19" height="25" viewBox="0 0 19 25">
        <path id="upload-icon"
          d="M-7047.6,1120.907a.573.573,0,0,1-.3-.293l-.1-.2V1108.5c0-11.846,0-11.919.1-12.109a.7.7,0,0,1,.283-.288c.175-.1.3-.1,5.749-.1h5.563v2.637c0,2.495,0,2.651.092,2.822a.788.788,0,0,0,.288.293l.195.107H-7029v9.277c0,9.272,0,9.282-.1,9.477a.568.568,0,0,1-.3.293c-.2.088-.458.093-9.1.093S-7047.4,1120.995-7047.6,1120.907Zm8.584-17.378c-.151.161-4.458,5.952-4.526,6.084a.73.73,0,0,0,.312.942c.151.083.292.093,1.35.093h1.184v2.637c0,2.495,0,2.651.092,2.822a.788.788,0,0,0,.288.293c.19.107.224.107,1.807.107,1.525,0,1.628-.005,1.808-.1a.657.657,0,0,0,.292-.293c.092-.186.1-.283.1-2.832v-2.637h1.184c1.057,0,1.2-.01,1.35-.093a.729.729,0,0,0,.311-.942c-.146-.288-4.506-6.069-4.637-6.152a.8.8,0,0,0-.414-.106A.692.692,0,0,0-7039.012,1103.529Zm.511,11.514h-.73v-2.637c0-2.549-.005-2.646-.1-2.832-.17-.337-.346-.391-1.291-.391h-.8l1.441-1.929c.794-1.06,1.462-1.929,1.481-1.929s.687.869,1.481,1.929l1.442,1.929h-.809c-.75,0-.823.01-1,.107a.788.788,0,0,0-.288.293c-.088.171-.092.327-.092,2.823v2.637Zm3.654-14.648v-2.007a8.039,8.039,0,0,1,.063-1.968c.1.063,5.12,3.833,5.2,3.906.059.059-.331.069-2.6.069Z"
          transform="translate(7048 -1096)" fill="#4990e1" />
      </svg>
    </div>

    <h4 *ngIf="maxFiles == 1">{{ titleTxt ? titleTxt : 'forms.drag-drop-cv' | translate }}</h4>
    <h4 *ngIf="maxFiles != 1">{{ titleTxt ? titleTxt :'forms.drag-drop-files' | translate }}</h4>
    <div>
      <span>{{ 'general.or' | translate }}</span>
      <label for="fileDropRef">{{ 'forms.Browse-files' | translate }}</label>
    </div>
  </div>
  <div *ngIf="selectedfilesCount > maxFiles" class="has-error">{{ 'forms.Max-files' | translate }} {{maxFiles}}</div>
  <div *ngIf="!validFile" class="has-error">{{ 'forms.InvalidFile' | translate }}</div>
  <div *ngIf="fileMaxSiZeErorr && validFile" class="has-error">{{ 'forms.Max-file-size' | translate }}</div>
  <div *ngIf="!files.length && isRequired && isSubmited" class="has-error">{{ 'forms.Required' | translate }}</div>

  <div class="dnd-container" *ngIf="!files.length" [hidden]='docsEditable'>
    <h4>No Files</h4>
  </div>

  <div class="files-list">
    <div class="file-item" *ngFor="let file of files; let i = index">
      <div class="file-details">
        <div class="file-icon">

          <img *ngIf='fileType(file.name) == "doc"' src="./assets/images/file-icons/file-doc-icon.svg">

          <img *ngIf='fileType(file.name) == "jpg"' src="./assets/images/file-icons/file-jpg-icon.svg">

          <img *ngIf='fileType(file.name) == "pdf"' src="./assets/images/file-icons/file-pdf-icon.svg">

          <img *ngIf='fileType(file.name) == "png"' src="./assets/images/file-icons/file-png-icon.svg">

          <img *ngIf='fileType(file.name) == "xls"' src="./assets/images/file-icons/file-xls-icon.svg">

          <img *ngIf='fileType(file.name) == "zip"' src="./assets/images/file-icons/file-zip-icon.svg">

        </div>
        <div>
          <label [title]="file?.name">{{ file?.name }}</label>
          <span>{{ formatBytes(file?.size) }}</span>
        </div>
      </div>
      <div class="file-btn">
        <a class="download" [href]="file.path" target="_blank" [download]="file.path"><svg width="16" height="16"
            viewBox="0 0 16 16">
            <path id="download-btn"
              d="M-168.666-163h-14.669a.667.667,0,0,1-.665-.669v-3.907a.668.668,0,0,1,.666-.67.669.669,0,0,1,.667.67v3.233h13.336v-3.233h0a.667.667,0,0,1,.665-.669h0a.667.667,0,0,1,.665.669h0v3.9a.667.667,0,0,1-.665.669Zm-7.823-4.45-3.831-4.143h0a.671.671,0,0,1-.178-.457.671.671,0,0,1,.668-.672.664.664,0,0,1,.49.215l2.675,2.895v-8.717A.669.669,0,0,1-176-179a.669.669,0,0,1,.667.671v8.711l2.674-2.893h0a.663.663,0,0,1,.49-.216.668.668,0,0,1,.667.67.67.67,0,0,1-.18.459l-3.83,4.148a.666.666,0,0,1-.488.213A.663.663,0,0,1-176.489-167.45Z"
              transform="translate(184 179)" fill="#3E74B3" />
          </svg></a>
        <button class="btn-delete" (click)="deleteFile(i, file.attachmentId)">
          <svg width="20" height="26" viewBox="0 0 20 26">
            <path id="delete-icon"
              d="M5617.238,13487a4.27,4.27,0,0,1-4.319-4.21v-13.66a.884.884,0,0,1,.9-.874h16.371a.884.884,0,0,1,.9.874v13.66a4.271,4.271,0,0,1-4.32,4.21Zm6.918-13.48v8.216a1.1,1.1,0,0,0,2.2,0v-8.216a1.1,1.1,0,0,0-2.2,0Zm-6.515,0v8.216a1.1,1.1,0,0,0,2.2,0v-8.216a1.1,1.1,0,0,0-2.2,0Zm-5.078-6.27a.555.555,0,0,1-.562-.546v-1.7a2.683,2.683,0,0,1,2.713-2.647h3.908v-.33a1.044,1.044,0,0,1,1.057-1.028h4.645a1.044,1.044,0,0,1,1.057,1.028v.33h3.908a2.684,2.684,0,0,1,2.713,2.647v1.7a.557.557,0,0,1-.563.546Z"
              transform="translate(-5612.001 -13461.004)" fill="#b6c3d4" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>