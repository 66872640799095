import { Component, OnInit } from '@angular/core';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';

@Component({
  selector: 'app-blank-layout',
  templateUrl: './blank-layout.component.html',
})
export class BlankLayoutComponent implements OnInit {
  constructor(private signalRService: SignalRServiceService) {}

  ngOnInit(): void {
    if (!this.signalRService.isConnectionStarted) {
      this.signalRService.startConnection();
    }
  }
}
