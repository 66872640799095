<footer>
  <div class="container p-0">
    <div>
      <img class="f-logo" src="./assets/images/Logo.png" alt="">

      <div class="btn-images">
        <a target="_blank" *ngFor="let item of appSetting" [href]="item.value">
          <img *ngIf="item.key == 0" src="./assets/images/Download-Play-Store-btn.svg" />
          <img *ngIf="item.key == 1" src="./assets/images/Download-App-Store-btn.svg" />
        </a>
      </div>
      <ul class="menu-footer">
        <li>
          <a href="https://www.unido.org/" target="_blank">{{ 'general.UNIDO' | translate }}</a>
        </li>
        <li>
          <a href="" [routerLink]="['/faq']">{{ 'footer.FAQ' | translate }}</a>
        </li>
        <li>
          <a href="" [routerLink]="['/policy']">{{ 'footer.Privacy-Policy' | translate }}</a>
        </li>
        <li>
          <a href="" [routerLink]="['/terms']">{{ 'footer.Terms-Conditions' | translate }}</a>
        </li>
        <li>
          <a href="" [routerLink]="['/support']">{{ 'header.Support' | translate }}</a>
        </li>
      </ul>
      <ul class="social-list">
        <ng-container *ngFor="let item of appSetting">
          <li *ngIf="item.key != 0 && item.key != 1 && item.key != 2">
            <a target="_blank" [href]="item.value">
              <span *ngIf="item.key == 5">
                <svg width="10" height="20" viewBox="0 0 10 20">
                  <path id="facebook-icon"
                    d="M17.775,3.225V7.215H15.766a.883.883,0,0,0-.748.493,2.142,2.142,0,0,0-.234,1.009v2.488h2.991v4.038H14.785v7.981H10.766V15.243H7.775V11.206h2.991V7.215a4,4,0,0,1,4.019-3.991Z"
                    transform="translate(-7.775 -3.225)" fill="#fff" />
                </svg>
              </span>
              <span *ngIf="item.key == 3">
                <svg width="20" height="16" viewBox="0 0 20 16">
                  <path id="twitter-icon"
                    d="M22.111,7.171a9.182,9.182,0,0,1-2.332.661,4.094,4.094,0,0,0,1.794-2.248,8.159,8.159,0,0,1-2.6.97,4.144,4.144,0,0,0-3.049-1.278,4.1,4.1,0,0,0-2.87,1.19A4.008,4.008,0,0,0,11.842,9.33a3.153,3.153,0,0,0,.135.926A12.291,12.291,0,0,1,7.2,8.978,11.426,11.426,0,0,1,3.5,6.025a4.074,4.074,0,0,0-.045,3.967,3.975,3.975,0,0,0,1.3,1.41,4.153,4.153,0,0,1-1.839-.485v.044a3.848,3.848,0,0,0,.919,2.579,4.147,4.147,0,0,0,2.354,1.388,4.148,4.148,0,0,1-1.076.132,3.186,3.186,0,0,1-.762-.088,4,4,0,0,0,1.435,2.006,4.1,4.1,0,0,0,2.377.815,7.468,7.468,0,0,1-2.332,1.278,8.463,8.463,0,0,1-2.735.441q-.493,0-.987-.044a12.322,12.322,0,0,0,2.937,1.322,11.4,11.4,0,0,0,3.341.485q5.7-.22,8.677-3.747a11.9,11.9,0,0,0,3.027-7.713l-.045-.529A8.742,8.742,0,0,0,22.111,7.171Z"
                    transform="translate(-2.111 -5.275)" fill="#fff" />
                </svg>
              </span>
              <span *ngIf="item.key == 4">
                <svg width="18" height="18" viewBox="0 0 18 18">
                  <path id="linkedin-icon"
                    d="M20,6H16.016V-.75a1.859,1.859,0,0,0-.727-1.359,2.329,2.329,0,0,0-1.523-.562,1.547,1.547,0,0,0-1.289.563A2.355,2.355,0,0,0,11.984-.75V6H8V-6h3.984v2.016A3.7,3.7,0,0,1,13.578-5.3,4.671,4.671,0,0,1,15.5-5.766,4.442,4.442,0,0,1,18.688-4.43,4.513,4.513,0,0,1,20-1.266ZM5.984,6H2V-6H5.984ZM4.016-12a1.869,1.869,0,0,1,1.406.586,1.987,1.987,0,0,1,.563,1.43,1.908,1.908,0,0,1-.562,1.406,1.908,1.908,0,0,1-1.406.563,1.987,1.987,0,0,1-1.43-.562A1.869,1.869,0,0,1,2-9.984a1.945,1.945,0,0,1,.586-1.43A1.945,1.945,0,0,1,4.016-12Z"
                    transform="translate(-2 12)" fill="#fff" />
                </svg>
              </span>
              <span *ngIf="item.key == 6">
                <svg width="20" height="20" viewBox="0 0 20 20">
                  <path id="instagram-icon"
                    d="M8.375,3.225h8.451A5.771,5.771,0,0,1,22.6,9V17.45a5.771,5.771,0,0,1-5.775,5.775H8.375A5.771,5.771,0,0,1,2.6,17.45V9A5.771,5.771,0,0,1,8.375,3.225ZM8.187,5.192a3.6,3.6,0,0,0-3.616,3.62v8.827a3.6,3.6,0,0,0,3.616,3.615h8.827a3.6,3.6,0,0,0,3.615-3.615V8.812a3.6,3.6,0,0,0-3.616-3.62ZM17.857,6.7a1.364,1.364,0,0,1,.868.4,1.221,1.221,0,1,1-1.733,0,1.364,1.364,0,0,1,.865-.4ZM12.6,8.2A5.049,5.049,0,1,1,9.055,9.68,4.98,4.98,0,0,1,12.6,8.2Zm0,2.019a3.035,3.035,0,0,0-2.113.892,2.867,2.867,0,0,0-.845,2.113A2.888,2.888,0,0,0,12.6,16.182a2.888,2.888,0,0,0,2.957-2.957,2.867,2.867,0,0,0-.845-2.113A3.035,3.035,0,0,0,12.6,10.22Z"
                    transform="translate(-2.6 -3.225)" fill="#4990e1" />
                </svg>
              </span>
              <span *ngIf="item.key == 7">
                <svg width="22" height="15" viewBox="0 0 22 15">
                  <path id="youtube-icon"
                    d="M11.368,17.025l5.724-3.207L11.368,10.61ZM24.111,8.656a11.471,11.471,0,0,1,.31,2.027q.1,1.28.1,2.23l.052.9a20.8,20.8,0,0,1-.465,5.161A2.533,2.533,0,0,1,22.2,20.83a16.967,16.967,0,0,1-2.914.3q-2.14.1-3.946.1l-1.758.05a46.933,46.933,0,0,1-8.612-.451,2.533,2.533,0,0,1-1.908-1.854,11.471,11.471,0,0,1-.31-2.027q-.1-1.28-.1-2.23l-.051-.9a20.8,20.8,0,0,1,.465-5.161A2.533,2.533,0,0,1,4.973,6.8a16.968,16.968,0,0,1,2.914-.3q2.14-.1,3.941-.1l1.758-.05a46.932,46.932,0,0,1,8.612.451,2.533,2.533,0,0,1,1.908,1.851Z"
                    transform="translate(-2.588 -6.318)" fill="#4990e1" />
                </svg>
              </span>
            </a>
          </li>

        </ng-container>
      </ul>
    </div>
    <div>
      <div class="copyRight">
        <div>
          <span class="bold-text">
            {{ 'footer.copy-right' | translate }}
          </span>
          <span class="year">
            {{ 'footer.2022' | translate }}
          </span>
          <span class="normal-text">
            {{ 'footer.All Rights Reserved' | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
</footer>
