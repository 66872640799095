import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ImageViewerService {
  constructor(private http: HttpClient) {}

  uploadImage(data: any, files: any) {
    const formData = new FormData();
    files.forEach((file: any) => {
      if (!file.attachmentId) {
        formData.append('file[]', file, file.name);
      }
    });
    formData.append('data', JSON.stringify(data));
    return this.http.post(`${environment.apiUrl}/ImageViewer/UploadImage`, formData);
  }
  getListCapturedImages(filterModel: any): any {
    return this.http.get(
      `${environment.apiUrl}/ImageViewer/ListCapturedImages`,
      {
        params: filterModel,
      }
    );
  }

  getGetImageDetails(id: any): any {
    return this.http.get(
      `${environment.apiUrl}/ImageViewer/GetImageDetails/${id}`
    );
  }

  UpdateImageTitle(obj: any): any {
    return this.http.post(
      `${environment.apiUrl}/ImageViewer/UpdateImageTitle` ,
      obj
    );
  }
  removeImage(id: number) {
    return this.http.delete(`${environment.apiUrl}/ImageViewer/RemoveImage/${id}`);
  }


// DELETE
// ​/api​/ImageViewer​/RemoveImage​/{imageId}

// POST
// ​/api​/ImageViewer​/AddRemark

// PUT
// ​/api​/ImageViewer​/UpdateRemark

// DELETE
// ​/api​/ImageViewer​/RemoveRemark​/{remarkId}

}
