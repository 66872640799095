import { Router } from '@angular/router';
import { Component, ElementRef, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ImageViewerService } from 'src/app/services/image-viewer.service';
import Swal from 'sweetalert2';
export interface DialogData {
  caseId: any;
}
@Component({
  selector: 'app-upload-form',
  templateUrl: './upload-form.component.html',
})
export class UploadFormComponent implements OnInit {
  loading = false;
  formChanged = false;
  formGroup: FormGroup;
  isSubmited = false;
  dataLoading = false;
  deletedFiles: [] = [];
  files: [] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<UploadFormComponent>,
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private route: Router,
    private imageViewerService: ImageViewerService,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.buildForms();
  }

  buildForms() {
    this.formGroup = this.formBuilder.group({
      title: [''],
      files: [this.files, Validators.required],
    });
    this.checkChanges();
  }
  updateFilesField($event: any) {
    this.files = $event;
    this.formGroup.controls.files.setValue(this.files);
  }
  hasError(formGroup: any, controlName: string, errorName: string) {
    return formGroup.controls[controlName].hasError(errorName);
  }

  checkChanges() {
    this.formGroup.valueChanges.subscribe((selectedValue) => {
      if (selectedValue) {
        this.formChanged = true;
      }
    });
  }
  closeDialog() {
    if (this.formChanged) {
      Swal.fire({
        title: this.translate.instant('swal.Areyousure'),
        text: this.translate.instant('swal.You will lose the data you entered'),
        icon: 'warning',
        confirmButtonText: this.translate.instant('swal.ok'),
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialogRef.close();
        } else if (result.isDenied) {
        }
      });
    } else {
      this.dialogRef.close();
    }
  }
  save() {
    this.isSubmited = true;
    if (this.formGroup.valid) {
      this.loading = true;
      let obj = {
        CaseId: this.data,
        Title: this.formGroup.controls.title.value,
      };
      if (!this.formGroup.controls.title.value) {
        this.files.map((e: any) => {
          obj.Title = e.name;
        });
      }
      this.imageViewerService.uploadImage(obj, this.files).subscribe(
        (res: any) => {
          this.loading = false;
          this.isSubmited = false;
          this.dialogRef.close(true);
          Swal.fire({
            title: this.translate.instant('swal.success'),
            text: this.translate.instant('swal.addedsuccessfully'),
            icon: 'success',
            confirmButtonText: this.translate.instant('swal.ok'),
          }).then(() => {
            this.route.navigate(['/viewer', this.data, res]);
          });
        },
        (err: any) => {
          this.loading = false;
          this.isSubmited = false;
          console.log(err);
        }
      );
    }
  }
  @HostListener('document:click', ['$event']) onDocumentClick( event: MouseEvent) {
    if (
      !this.el.nativeElement.contains(event.target) &&
      document.querySelector('.cdk-overlay-backdrop') == event.target
    ) {
      this.closeDialog()
    }
  }
}
