import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
 import { Lookup } from 'src/app/models/lookups/Lookup';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class RequestsService {
  TreedataChange = new BehaviorSubject<Lookup[]>([]);

  constructor(private http: HttpClient) {}

  getRequests(filterObj: any): any {
    return this.http.post(
      `${environment.apiUrl}/AssessmentRequest/GetEnterpriseRequests`,
      filterObj
    );
  }
  getRequestDetails(RequestId: any) {
    return this.http.get(
      `${environment.apiUrl}/AssessmentRequest/GetRequest/${RequestId}`
    );
  }
  sendExpertRespondMission(respondObj: any) {
    return this.http.post(
      `${environment.apiUrl}/AssessmentRequest/ExpertRespondMission`,
      respondObj
    );
  }

  AddExpertFeedback(feedbackObj: any, files: any) {
    const formData = new FormData();
    files.forEach((file: any) => {
      formData.append('file[]', file, file.name);
    });
    formData.append('data', JSON.stringify(feedbackObj));
    return this.http.post(
      `${environment.apiUrl}/AssessmentRequest/AddExpertFeedback`,
      formData
    );
  }
  AddEnterpriseFeedback(feedbackObj: any) {
    return this.http.post(
      `${environment.apiUrl}/AssessmentRequest/AddEnterpriseFeedback`,
      feedbackObj
    );
  }
  AddNewRequest(newRequestObj: any, files: any) {
    const formData = new FormData();
    files.forEach((file: any) => {
      formData.append('file[]', file, file.name);
    });
    formData.append('data', JSON.stringify(newRequestObj));
    return this.http.post(
      `${environment.apiUrl}/AssessmentRequest/NewRequest`,
      formData
    );
  }
  UpdateRequest(RequestObj: any, files: any) {
    const formData = new FormData();
    files.forEach((file: any) => {
      if (file.attachmentId === undefined) {
        // send new files
        formData.append('file[]', file, file.name);
      }
    });
    formData.append('data', JSON.stringify(RequestObj));
    return this.http.post(
      `${environment.apiUrl}/AssessmentRequest/UpdateRequest`,
      formData
    );
  }

  // new
  getProfileDetails(isExpert: boolean) {
    if (isExpert) {
      return this.http.get(`${environment.apiUrl}/Expert/GetExpertDetails`);
    } else {
      return this.http.get(`${environment.apiUrl}/Enterprise/GetEnterpriseDetails`);
    }
  }

 enterpriseEditProfileRequest(requestObj: any) {
    const formData = new FormData();
  formData.append('data', JSON.stringify(requestObj));
    return this.http.post(
      `${environment.apiUrl}/Enterprise/SendEditProfileRequest` ,
      formData
    );
  }

  changePassword(changePasswordObj: any) {
    return this.http.post(
      `${environment.apiUrl}/Authentication/ChangePassword`,
      changePasswordObj
    );
  }

  validateEmail(data: any) {
    return this.http.get(`${environment.apiUrl}/Expert/ValidateEmail`, {
      params: data,
    });
  }

  updateExpert(user: any, files: any) {
    const formData = new FormData();
    files.forEach((file: any) => {
      if (!file.attachmentId) {
        formData.append('file[]', file, file.name);
      }
    });
    formData.append('data', JSON.stringify(user));
    return this.http.post(
      `${environment.apiUrl}/Expert/SendEditProfileRequest`,
      formData
    );
  }
}
