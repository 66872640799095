import { UserTypes } from './UserTypes';
export class User {
    id: string;
    userId: string;
    fullName: string;
    userType: UserTypes = 1;
    email: string;
    username: string;
    password: string;
    token: string;
    role: string;
    profilePicture: string;
    // tslint:disable-next-line: variable-name
    accessToken: string;
    permissions: string[]= [];
    name: string;
}
