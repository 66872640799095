export const environment = {
  production: false,
  environmentName: "test",

  googleSiteKey: '6Leb6VwkAAAAAPFbm0YX9tK7UYglY8ilegEIVpfZ',

  // test
  apiUrl: 'https://unido-emiptestapi.expertapps.com.sa/api',
  signalRUrl: 'https://unido-emiptestslr.expertapps.com.sa/hubs',
  loginUrl: 'https://unido-emiptestapi.expertapps.com.sa/api/Authentication/Login',
};
