import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';
import { BlankLayoutComponent } from './layout/blank-layout/blank-layout.component';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '', loadChildren: () => import('./features/home/home.module').then( (m) => m.HomeModule),
      },
      {
        path: 'home', loadChildren: () => import('./features/home/home.module').then( (m) => m.HomeModule),
      },
      {
        path: 'auth', loadChildren: () => import('./features/authentication/authentication.module').then( (m) => m.AuthenticationModule),
      },
      {
        path: 'terms', loadChildren: () => import('./features/pages/termsandcondition/termsandcondition.module').then(m => m.TermsandconditionModule)
      },
      {
        path: 'faq', loadChildren: () => import('./features/pages/faq/faq.module').then(m => m.FaqModule)
      },
      {
        path: 'policy', loadChildren: () => import('./features/pages/policy/policy.module').then(m => m.PolicyModule)
      },
      {
        path: 'support', loadChildren: () => import('./features/pages/support/support.module').then(m => m.SupportModule)
      },
      {
        path: 'projects', loadChildren: () => import('./features/projects/projects.module').then(m => m.ProjectsModule)
      },
      {
        path: '404',
        loadChildren: () =>
          import('./features/pages/not-found/not-found.module').then(
            (m) => m.NotFoundModule
          ),
      },
    ],
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      { path: 'evaluate-productivity-mobile', loadChildren: () => import('./features/evaluate-productivity/evaluate-productivity.module').then(m => m.EvaluateProductivityModule)},
      { path: 'mobile-viewer', loadChildren: () => import('./features/viewer/viewer.module').then(m => m.ZoomViewerModule) },
     ]
    },
    {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'profile', loadChildren: () => import('./features/profile/my-profile.module').then(m => m.MyProfileModule) },
      { path: 'kpis', loadChildren: () => import('./features/kpis/kpis.module').then(m => m.KpisModule) },
      { path: 'application', loadChildren: () => import('./features/application/application.module').then(m => m.ApplicationModule) },
      { path: 'evaluate-productivity', loadChildren: () => import('./features/evaluate-productivity/evaluate-productivity.module').then(m => m.EvaluateProductivityModule)},
    ],
  },
  {
    path: 'viewer',
    component: BlankLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', loadChildren: () => import('./features/viewer/viewer.module').then(m => m.ZoomViewerModule) },
     ]
  },
  {
    path: 'print',
    component: BlankLayoutComponent,
    canActivate: [AuthGuard],
    children: [
       { path: '', loadChildren: () => import('./features/shared-print/shared-print.module').then(m => m.SharedPrintModule) },
    ]
  },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }


];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollOffset: [0, 64]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
