import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-other-questions',
  templateUrl: './other-questions.component.html',
})
export class OtherQuestionsComponent implements OnInit {
  @Output() AnswerQues = new EventEmitter<any>();
  @Output() validAnswer = new EventEmitter<{}>();
  @Output() AnswerIndex = new EventEmitter<{}>();
  @Input() answerListing!: any;
  @Input() editMode = false;
  @Input() isSubmited = false;
  @Input() isReset = false;
  @Input() isRequired = false;
  @Input() isAnswered = false;
  @Input() question: any;
  answIndxOtherAnswer: any;
  selectedOption: any;
  options: any[] = [];
  multiSelectIds: any[] = [];
  qutionsAnswerd: any[] = [];
  qustionsIds: any[] = [];
  oneAnswer: any = {};
  checkedItems: any[] = [];
  noChecked = false;
  otherChecked = false;
  clear = false;
  selectedIds: any[] = [];

  multiChoiceControl = new FormControl('', [Validators.required]);
  textareaControl = new FormControl('', [Validators.required]);
  numberControl = new FormControl('', [Validators.required]);
  textControl = new FormControl('', [Validators.required]);
  dateControl = new FormControl(null, [Validators.required]);
  timeControl = new FormControl('', [Validators.required]);
  onechoiceControl = new FormControl('', [Validators.required]);
  otherQuesControl = new FormControl('', [Validators.required]);
  constructor() {}

  ngOnInit(): void {

    // this.options = this.qustions.map((q: any) => q);
    if (this.editMode) {
      // console.log('answerListing', this.answerListing);
      // console.log('question', this.question);
    }
    this.bindData();
  }

  bindData() {
    if (this.question.questionType.code == 1) {
      this.question.options.forEach((option: any) => {
        if (
          this.question.answersIds.findIndex(
            (e: any) => e.questionValueVersionId == option.id
          ) != -1
        ) {
          this.multiSelectIds.push(option.id);
          this.multiChoiceControl.setValue(this.multiSelectIds);
          option.ischecked = true;
          if (option.isOther) {
            this.otherChecked = !this.otherChecked;
          }
          this.question.answersIds.map((e: any) => {
            if (e.isOtherAnswer) {
              this.otherQuesControl.setValue(e.text);
            }
          });
          this.changeStatus(this.question, option, true);
        }
      });
    }
    if (this.question.questionType.code == 2) {
      this.question.options.forEach((option: any) => {
        if (
          this.question.answersIds.findIndex(
            (e: any) => e.questionValueVersionId == option.id
          ) != -1
        ) {
          this.onechoiceControl.setValue(option.id);
          option.ischecked = true;
          this.otherQuesControl.setValue(this.question.answersIds[0].text);
          this.radioListChange(this.question, option, true);
        }
      });
    }
    if (this.question.questionType.code == 3) {
      this.textareaControl.setValue(this.question.answerText);
      this.setAnswer(this.textareaControl, this.question);
    }
    if (this.question.questionType.code == 4) {
      this.numberControl.setValue(this.question.answerText);
      this.setAnswer(this.numberControl, this.question);
    }
    if (this.question.questionType.code == 5) {
      this.textControl.setValue(this.question.answerText);
      this.setAnswer(this.textControl, this.question);
    }
    if (this.question.questionType.code == 6) {
      this.dateControl.setValue(this.question.answerText);
      this.setAnswer(this.dateControl, this.question);
    }
    if (this.question.questionType.code == 7) {
      this.timeControl.setValue(this.question.answerText);
      this.setAnswer(this.timeControl, this.question);
    }
  }

  checkOtherValue() {
    if (this.otherQuesControl.value) {
      this.oneAnswer.answersIds.map((ele: any) => {
        if (ele.isOtherAnswer) {
          ele.text = this.otherQuesControl.value;
        }
      });
      this.AnswerQues.emit(this.oneAnswer);
      this.validAnswer.emit(true);
    } else {
      this.validAnswer.emit(false);
    }
  }

  changeStatus(question: any, option: any, event: any) {
     // this.otherChecked = this.oneQustion.allowOtherAnswer;
    this.oneAnswer.applicationOtherQuestionId = question.applicationQuestionId;
    this.oneAnswer.questionTypeCode = question.questionType.code;

    // isother
    if (option.isOtherAnswer) {
      this.otherChecked = !this.otherChecked;
    }

    if (event.checked || event == true || event.source._selected) {
      option.ischecked = true;
      this.checkedItems.push({
        questionValueVersionId: option.id,
        isOtherAnswer: option.isOtherAnswer,
        text: '',
      });
      this.oneAnswer.answersIds = this.checkedItems;
      if (option.isOtherAnswer) {
        this.checkOtherValue();
      }
      if (!option.isOtherAnswer) {
        this.AnswerQues.emit(this.oneAnswer);
        this.validAnswer.emit(true);
      }
    } else {
      option.ischecked = false;
      this.checkedItems = this.checkedItems.filter((item) => {
        return item.questionValueVersionId !== option.id;
      });
      if (this.checkedItems.length) {
        this.AnswerQues.emit(this.oneAnswer);
        this.validAnswer.emit(true);
      }
      this.oneAnswer.answersIds = this.checkedItems;
      // isother
      if (option.isOtherAnswer) {
        this.otherQuesControl.setValue('');
      }
      this.AnswerQues.emit(this.oneAnswer);
    }
    // error msg
    if (!this.checkedItems.length) {
      this.noChecked = true;
    }
    // || (option.isOtherAnswer && this.otherQuesControl.value === '')
    if (!this.checkedItems.length) {
      this.validAnswer.emit(false);
    }
    // console.log(this.checkedItems);
  }

  changeStatusCheck(question: any, option: any, event: any) {
    this.oneAnswer.applicationOtherQuestionId = question.applicationQuestionId;
    this.oneAnswer.questionTypeCode = question.questionType.code;
    if (option.isOtherAnswer) {
      this.otherChecked = !this.otherChecked;
    }
    if (event.checked) {
      option.ischecked = true;
      this.checkedItems.push({
        questionValueVersionId: option.id,
        isOtherAnswer: option.isOtherAnswer,
        text: '',
      });
      this.oneAnswer.answersIds = this.checkedItems;
      if (option.isOtherAnswer) {
        this.checkOtherValue();
      } else {
        this.AnswerQues.emit(this.oneAnswer);
        this.validAnswer.emit(true);
      }
    } else {
      this.checkedItems = this.checkedItems.filter(
        (item) => item.questionValueVersionId !== option.id
      );
      if (!this.checkedItems.length) {
        this.validAnswer.emit(false);
      }
      this.oneAnswer.answersIds = this.checkedItems;
      this.AnswerQues.emit(this.oneAnswer);
      if (option.isOtherAnswer) {
        this.otherQuesControl.setValue('');
        this.checkOtherValue();
      }
    }
    if (!this.checkedItems.length) {
      this.validAnswer.emit(false);
    }
  }
  radioListChange(question: any, option: any, event: any) {
    let checkedItems = [];
    this.oneAnswer.applicationOtherQuestionId = question.applicationQuestionId;
    this.oneAnswer.questionTypeCode = question.questionType.code;
    checkedItems.push({
      questionValueVersionId: option.id,
      isOtherAnswer: option.isOtherAnswer,
      text: '',
    });
    this.oneAnswer.answersIds = checkedItems;

    if (!option.isOtherAnswer) {
      this.oneAnswer.text = '';
      this.otherQuesControl.setValue('');
      this.AnswerQues.emit(this.oneAnswer);
      this.validAnswer.emit(true);
    }
    if (option.isOtherAnswer) {
      this.otherChecked = true;
      this.checkOtherValue();
    }

    if (option.isOtherAnswer && this.otherQuesControl.value === '') {
      this.validAnswer.emit(false);
    }
  }

  setAnswer(control: any, question: any) {
    let answer: any = {};
    if (control.value) {
      answer = {
        applicationOtherQuestionId: question.applicationQuestionId,
        questionTypeCode: question.questionType.code,
        text: control.value,
        answersIds: [],
      };
      if (answer) {
        this.AnswerQues.emit(answer);
        this.validAnswer.emit(true);
      }
    } else {
      this.validAnswer.emit(false);
    }
  }

  setAnswerForTime(control: any, question: any) {
    let answer: any = {};
    if (control) {
      answer = {
        applicationOtherQuestionId: question.applicationQuestionId,
        questionTypeCode: question.questionType.code,
        text: control,
        answersIds: [],
      };
      if (answer) {
        this.AnswerQues.emit(answer);
        this.validAnswer.emit(true);
      }
    } else {
      this.validAnswer.emit(false);
    }
  }
}
