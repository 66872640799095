<div class="questionaire-container">

    <div *ngIf="question.questionTypeCode  === 2" class="Question-container">
      <mat-form-field class="form-group" appearance="fill">
        <mat-label>{{ 'kpis.Select' | translate }}</mat-label>
        <mat-select multiple [formControl]="multiChoiceControl" (selectionChange)="fixDuplicate($event)">
          <mat-option *ngFor="let option of question.options" [value]="option.questionOptionId" (onSelectionChange)="option.ischecked = true;changeStatus(question ,option , $event)">
            {{option.text}}
          </mat-option>
        </mat-select>
      </mat-form-field>

        <div *ngIf="otherChecked" class="form-group">
          <textarea class="other-quest" [formControl]="otherQuesControl" (keyup)='checkOtherValue()' maxlength="500"
            required [ngClass]="{'inValid':  otherQuesControl.touched && otherQuesControl.errors?.required }"></textarea>
          <div class="has-error" *ngIf="otherQuesControl.touched && otherQuesControl.errors?.required">{{ 'forms.Required'
            | translate }}</div>
        </div>
    </div>
    <div *ngIf="question.questionTypeCode === 1" class="Question-container">
      <mat-radio-group class="form-group radio-list" [formControl]="onechoiceControl">
        <div *ngFor="let option of question.options; let i = index">
          <mat-radio-button (change)="option.ischecked = true;radioListChange(question, option, $event)" [value]="option.questionOptionId">{{option.text}}</mat-radio-button>
        </div>
      </mat-radio-group>
      <div *ngIf="otherChecked" class="form-group">
        <textarea class="other-quest" [formControl]="otherQuesControl" (keyup)='checkOtherValue()' maxlength="500" required
          [ngClass]="{'inValid':  otherQuesControl.touched && otherQuesControl.errors?.required }"></textarea>
        <div class="has-error" *ngIf="otherQuesControl.touched && otherQuesControl.errors?.required">{{ 'forms.Required' |
          translate }}</div>
      </div>
    </div>
    <!-- <div *ngIf="question.questionTypeCode === 3" class="Question-container">
      <mat-form-field class="form-group" appearance="fill">
        <mat-label>{{ 'forms.please-answer' | translate }}</mat-label>
        <textarea matInput [formControl]="textareaControl" required
          (keyup)="setAnswer(textareaControl, question, qustions , $event )"></textarea>
        <mat-error *ngIf="textareaControl.errors?.required">{{ 'forms.Required' | translate }}</mat-error>
      </mat-form-field>
    </div> -->


    <div *ngIf="question.questionTypeCode  === 3" class="Question-container">
      <mat-form-field class="form-group" appearance="fill">
        <mat-label>{{ 'forms.please-answer' | translate }}</mat-label>
        <input matInput [formControl]="numberControl" appOnlynumber required
          (keyup)="setAnswer(numberControl, question  )">
        <mat-error *ngIf="numberControl.errors?.required">{{ 'forms.Required' | translate }}</mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="question.questionTypeCode  === 4" class="Question-container">
      <mat-form-field class="form-group" appearance="fill">
        <mat-label>{{ 'forms.please-answer' | translate }}</mat-label>
        <input matInput [formControl]="numberControl" appOnlynumber required
          (keyup)="setAnswer(numberControl, question )">
        <mat-error *ngIf="numberControl.errors?.required">{{ 'forms.Required' | translate }}</mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="question.questionTypeCode  === 5" class="Question-container">
      <mat-form-field class="form-group" appearance="fill">
        <mat-label>{{ 'forms.please-answer' | translate }}</mat-label>
        <input matInput [formControl]="textControl" required (keyup)="setAnswer(textControl, question)">
        <mat-error *ngIf="textControl.errors?.required">{{ 'forms.Required' | translate }}</mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="question.questionTypeCode  === 6" class="Question-container">
      <mat-form-field class="form-group" appearance="fill">
        <mat-label>{{ 'forms.please-answer' | translate }}</mat-label>
        <input matInput [formControl]="dateControl" required (dateChange)="setAnswer(dateControl, question)" [matDatepicker]="pickerFrom" readonly>
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
        <mat-error *ngIf="dateControl.errors?.required">{{ 'forms.Required' | translate }}</mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="question.questionTypeCode  === 7" class="Question-container">
      <!-- {{timeControl.value}} -->
      <mat-form-field class="form-group matTimePicker">
        <mat-label>{{ 'forms.please-answer' | translate }}</mat-label>
        <input matInput [formControl]="timeControl" required [ngxTimepicker]="TimePicker" [format]="24" readonly [disableClick]="true" (ngModelChange)="setAnswer(timeControl, question)">
        <mat-icon *ngIf="timeControl.value"
            (click)="timeControl.setValue('')"
            class="clear-icon">clear
        </mat-icon>
        <ngx-material-timepicker-toggle [for]="TimePicker">
        </ngx-material-timepicker-toggle>
        <!-- (timeSet)="settimeAnswer('timeControl', oneQustion.questionnaireQuestionId)" -->
        <ngx-material-timepicker #TimePicker >
        </ngx-material-timepicker>
        <mat-error *ngIf="timeControl.errors?.required">{{ 'forms.Required' | translate }}</mat-error>
    </mat-form-field>
    </div>


</div>
