<div class="files-preview-container" [ngClass]="{'active': docsEditable}">
  <div class="files-list">
      <div class="file-item" *ngFor="let file of files; let i = index">
          <div class="file-details">
              <div class="file-icon">

                  <img *ngIf='fileType(file.name) == "doc"'
                      src="./assets/images/file-icons/file-doc-icon.svg">

                  <img *ngIf='fileType(file.name) == "jpg"'
                      src="./assets/images/file-icons/file-jpg-icon.svg">

                  <img *ngIf='fileType(file.name) == "pdf"'
                      src="./assets/images/file-icons/file-pdf-icon.svg">

                  <img *ngIf='fileType(file.name) == "png"'
                      src="./assets/images/file-icons/file-png-icon.svg">

                  <img *ngIf='fileType(file.name) == "xls"'
                      src="./assets/images/file-icons/file-xls-icon.svg">

                  <img *ngIf='fileType(file.name) == "zip"'
                      src="./assets/images/file-icons/file-zip-icon.svg">

              </div>
              <div>
                  <label [title]="file?.name">{{ file?.name }}</label>
                  <span>{{ formatBytes(file?.size) }}</span>
              </div>
          </div>
          <div class="file-btn" *ngIf="!isDownloadPDF">
              <a class="download" [href]="file.path" target="_blank" (click)="downloadImg(file)" [download]="file.path"><svg
                      width="16" height="16" viewBox="0 0 16 16">
                      <path id="download-btn"
                          d="M-168.666-163h-14.669a.667.667,0,0,1-.665-.669v-3.907a.668.668,0,0,1,.666-.67.669.669,0,0,1,.667.67v3.233h13.336v-3.233h0a.667.667,0,0,1,.665-.669h0a.667.667,0,0,1,.665.669h0v3.9a.667.667,0,0,1-.665.669Zm-7.823-4.45-3.831-4.143h0a.671.671,0,0,1-.178-.457.671.671,0,0,1,.668-.672.664.664,0,0,1,.49.215l2.675,2.895v-8.717A.669.669,0,0,1-176-179a.669.669,0,0,1,.667.671v8.711l2.674-2.893h0a.663.663,0,0,1,.49-.216.668.668,0,0,1,.667.67.67.67,0,0,1-.18.459l-3.83,4.148a.666.666,0,0,1-.488.213A.663.663,0,0,1-176.489-167.45Z"
                          transform="translate(184 179)" fill="#3E74B3" />
              </svg></a>
          </div>
      </div>
  </div>
</div>
