import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
 import { NotificationsSearchModel } from '../models/notification/NotificationsSearchModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private http: HttpClient) {}

  getNotifications(searchModel: any) {
    return this.http.get(`${environment.apiUrl}/Notification/List`, {
      params: searchModel,
    });
  }
  updateNotificationsSeenStatus() {
    return this.http.get(`${environment.apiUrl}/Notification/SeeNotification`);
  }
}
