<mat-dialog-content class="createUserDialog">
  <section class="createUser">
    <div class="auth-card fill-details">
         <img class="img-fluid" src="./assets/images/application_illustration.png">
        <div class="text-center details">
          <h5>{{ 'requestes.Request Application' | translate}} </h5>
          <p>{{ 'requestes.fillText' | translate}}</p>
        </div>
        <div class="text-center">
          <button mat-raised-button mat-dialog-close color="gray">{{ 'requestes.Dismiss' | translate}}</button>
          <button mat-raised-button color="primary" (click)="requestDetails()">{{ 'requestes.Fill out Application' | translate}}</button>
        </div>
    </div>
  </section>
</mat-dialog-content>
