<div class="questionaire-container">
  <div *ngIf="question.questionType.code  === 1" class="Question-container">
    <div class="form-group">
      <div *ngFor="let option of question.options; let i = index">
        <mat-checkbox (change)="changeStatus(question, option, $event)" [value]="option.id"
          [checked]="option.ischecked">{{option.text}}</mat-checkbox>
      </div>
      <div *ngIf="otherChecked" class="form-group">
        <textarea class="other-quest" [formControl]="otherQuesControl" (keyup)='checkOtherValue()' maxlength="500"
          required [ngClass]="{'inValid':  otherQuesControl.touched && otherQuesControl.errors?.required }"></textarea>
        <div class="has-error" *ngIf="otherQuesControl.touched && otherQuesControl.errors?.required">{{ 'forms.Required'
          | translate }}</div>
      </div>
    </div>
  </div>
  <!-- <div *ngIf="question.questionType.code  === 2" class="Question-container">
    <mat-form-field class="form-group" appearance="fill">
      <mat-label>{{ 'kpis.Select' | translate }}</mat-label>
      <mat-select multiple [formControl]="multiChoiceControl">
        <mat-option *ngFor="let option of question.options" [value]="option" (onSelectionChange)="changeStatus(question ,option , $event)">
          {{option.text}}
        </mat-option>
      </mat-select>
    </mat-form-field>
      <div *ngIf="otherChecked" class="form-group">
        <textarea class="other-quest" [formControl]="otherQuesControl" (keyup)='checkOtherValue(question)' maxlength="30"
          required [ngClass]="{'inValid':  otherQuesControl.touched && otherQuesControl.errors?.required }"></textarea>
        <div class="has-error" *ngIf="otherQuesControl.touched && otherQuesControl.errors?.required">{{ 'forms.Required'
          | translate }}</div>
      </div>
  </div> -->
  <div *ngIf="question.questionType.code  === 2" class="Question-container">
    <mat-radio-group class="form-group radio-list" [formControl]="onechoiceControl">
      <div *ngFor="let option of question.options; let i = index">
        <mat-radio-button (change)="radioListChange(question, option, $event)"
          [value]="option.id">{{option.text}}</mat-radio-button>
      </div>
    </mat-radio-group>
    <div *ngIf="otherChecked" class="form-group">
      <textarea class="other-quest" [formControl]="otherQuesControl" (keyup)='checkOtherValue()' maxlength="500" required
        [ngClass]="{'inValid':  otherQuesControl.touched && otherQuesControl.errors?.required }"></textarea>
      <div class="has-error" *ngIf="otherQuesControl.touched && otherQuesControl.errors?.required">{{ 'forms.Required' |
        translate }}</div>
    </div>
  </div>
  <div *ngIf="question.questionType.code === 3" class="Question-container">
    <mat-form-field class="form-group" appearance="fill">
      <mat-label>{{ 'forms.please-answer' | translate }}</mat-label>
      <textarea matInput [formControl]="textareaControl" required maxlength="500"
        (keyup)="setAnswer(textareaControl, question)"></textarea>
      <mat-error *ngIf="textareaControl.errors?.required">{{ 'forms.Required' | translate }}</mat-error>
    </mat-form-field>
  </div>
  <div *ngIf="question.questionType.code  === 4" class="Question-container">
    <mat-form-field class="form-group" appearance="fill">
      <mat-label>{{ 'forms.please-answer' | translate }}</mat-label>
      <input matInput [formControl]="numberControl" appOnlynumber required
        (keyup)="setAnswer(numberControl, question )">
      <mat-error *ngIf="numberControl.errors?.required">{{ 'forms.Required' | translate }}</mat-error>
    </mat-form-field>
  </div>
  <div *ngIf="question.questionType.code  === 5" class="Question-container">
    <mat-form-field class="form-group" appearance="fill">
      <mat-label>{{ 'forms.please-answer' | translate }}</mat-label>
      <input matInput [formControl]="textControl" required (keyup)="setAnswer(textControl, question)">
      <mat-error *ngIf="textControl.errors?.required">{{ 'forms.Required' | translate }}</mat-error>
    </mat-form-field>
  </div>
  <div *ngIf="question.questionType.code  === 6" class="Question-container">
    <mat-form-field class="form-group" appearance="fill">
      <mat-label>{{ 'forms.please-answer' | translate }}</mat-label>
      <input matInput [formControl]="dateControl" required (dateChange)="setAnswer(dateControl, question)"
        [matDatepicker]="pickerFrom" readonly>
      <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
      <mat-datepicker #pickerFrom></mat-datepicker>
      <mat-error *ngIf="dateControl.errors?.required">{{ 'forms.Required' | translate }}</mat-error>
    </mat-form-field>
  </div>
  <div *ngIf="question.questionType.code  === 7" class="Question-container">
    <!-- {{timeControl.value}} -->
    <mat-form-field class="form-group matTimePicker">
      <mat-label>{{ 'forms.please-answer' | translate }}</mat-label>
      <input matInput [formControl]="timeControl" required [ngxTimepicker]="TimePicker" readonly [disableClick]="true">
      <mat-icon *ngIf="timeControl.value" (click)="timeControl.setValue('')" class="clear-icon">clear
      </mat-icon>
      <ngx-material-timepicker-toggle [for]="TimePicker">
      </ngx-material-timepicker-toggle>
      <!-- (timeSet)="settimeAnswer('timeControl', oneQustion.questionnaireQuestionId)" -->
      <ngx-material-timepicker #TimePicker [format]="24" (timeSet)="setAnswerForTime($event, question)">
      </ngx-material-timepicker>
      <mat-error *ngIf="timeControl.errors?.required">{{ 'forms.Required' | translate }}</mat-error>
    </mat-form-field>
  </div>
</div>
