import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class LookupsService {

  currentSteps = new BehaviorSubject<number>(0);
  clearSectors = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
  }

  GetFAQs() {
    return this.http.get(`${environment.apiUrl}/Configurations/GetFAQs`);
  }
  GetPolicies() {
    return this.http.get(`${environment.apiUrl}/Configurations/GetPortalPolicies`);
  }

  AddSupportRequest(contactObj: any) {
    return this.http.post(`${environment.apiUrl}/Configurations/AddSupportRequest`,contactObj);
  }

  getCountries() {
    return this.http.get(`${environment.apiUrl}/lookup/AllCountries`);
  }
  getCities(countryId: any) {
    return this.http.get(`${environment.apiUrl}/lookup/Cities/${countryId}`);
  }
  getLanguages() {
    return this.http.get(`${environment.apiUrl}/Language/GetLanguages`);
  }
  getProducts() {
    return this.http.get(`${environment.apiUrl}/lookup/ProductsServicesProvided`);
  }
  getCommunications() {
    return this.http.get(`${environment.apiUrl}/lookup/GetCommunicationPlatforms`);
  }

  EnterpriseQuestionnaire() {
    return this.http.get(`${environment.apiUrl}/lookup/EnterpriseQuestionnaire`);
  }

  // new
  GetCountriesForExpert(text: any) {
    return this.http.get(`${environment.apiUrl}/Country/GetCountriesForExpert?searchText=${text}`);
  }

  GetSectorsForExpert(text: any) {
    return this.http.get(`${environment.apiUrl}/Sector/GetSectorsForExpert?searchText=${text}`);
  }

  GetSpecializationsForExpert(text: any) {
    return this.http.get(`${environment.apiUrl}/Specialization/GetSpecializationsForExpert?searchText=${text}`);
  }

  GetLanguagesForExpert(text: any) {
    return this.http.get(`${environment.apiUrl}/Language/GetLanguagesForExpert?searchText=${text}`);
  }

  GetProjectsForExpert(data: any) {
    return this.http.get(`${environment.apiUrl}/Project/GetProjectsForExpert`, {
      params: data,
    });
  }

  GetProjectSectorsForExpert(data: any) {
    return this.http.get(`${environment.apiUrl}/Project/GetProjectSectorsForExpert`, {
      params: data,
    });
  }

  GetProjectSpecializationsForExpert(data: any) {
    return this.http.get(`${environment.apiUrl}/Project/GetProjectSpecializationsForExpert`, {
      params: data,
    });
  }

  GetProjectCountriesForExert(data: any) {
    return this.http.get(`${environment.apiUrl}/Project/GetProjectCountriesForExert`, {
      params: data,
    });
  }

  GetPositionsDistinct(text: any) {
    return this.http.get(`${environment.apiUrl}/lookup/GetPositionsDistinct?searchText=${text}`);
  }

  GetLanguagesAnonymous(text: any) {
    return this.http.get(`${environment.apiUrl}/Language/GetLanguagesAnonymous?searchText=${text}`);
  }

  GetCountriesAnonymous(text: any) {
    return this.http.get(`${environment.apiUrl}/Country/GetCountriesAnonymous?searchText=${text}`);
  }

  GetCity(text: any, countryId: any) {
    return this.http.get(`${environment.apiUrl}/Country/GetCities?searchText=${text}&countryId=${countryId}`);
  }

  GetCompaniesSizes() {
    return this.http.get(`${environment.apiUrl}/Lookup/GetCompaniesSizes`);
  }

  GetProductsAndServicesDistinct(text: any){
    return this.http.get(`${environment.apiUrl}/Lookup/GetProductsAndServicesDistinct?searchText=${text}`);
  }

  GetProjectsByCountry(data: any) {
    return this.http.get(`${environment.apiUrl}/Project/GetProjectsByCountry`, {
      params: data,
    });
  }

  GetProjectSectors(data: any) {
    return this.http.get(`${environment.apiUrl}/Project/GetProjectSectors`, {
      params: data,
    });
  }

  GetProjectSpecializations(data: any) {
    return this.http.get(`${environment.apiUrl}/Project/GetProjectSpecializations`, {
      params: data,
    });
  }

  GetProjectQuestionnaires(data: any) {
    return this.http.get(`${environment.apiUrl}/Questionnaire/GetProjectQuestionnaires`, {
      params: data,
    });
  }

  GetQuestionnaireQuestionsAnonymous(questionnaireId: any) {
    return this.http.get(`${environment.apiUrl}/Questionnaire/GetQuestionnaireQuestionsAnonymous?questionnaireId=${questionnaireId}`);
  }

  checkDistinctEnterprise(data: any){
    return this.http.get(`${environment.apiUrl}/Enterprise/DistinctEnterprise`, {
      params: data,
    });
  }

  getDistinctTaxOrRegirtrationId(data: any){
    return this.http.get(`${environment.apiUrl}/Enterprise/DistinctTaxOrRegirtrationId`, {
      params: data,
    });
  }

  getTermsAndConditions(){
    return this.http.get(`${environment.apiUrl}/TermsAndConditions/GetTermsAndConditions`);
  }

  getRegistrationRequest(userid: any){
    return this.http.get(`${environment.apiUrl}/Enterprise/GetRegistrationRequest/${userid}`);
  }

  getAdditionalInfoQuestions(){
    return this.http.get(`${environment.apiUrl}/Enterprise/GetAdditionalInfoQuestions`);
  }
  getIpAddress() {
    return this.http.get(`${environment.apiUrl}/Lookup/GetIPAddress`);
  }


}
