import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  appSetting:any = [];
  constructor(private homeService: HomeService) {}

  // encodeURIComponent
  ngOnInit() {
    this.getAppSetting();
  }

  getAppSetting() {
    this.homeService.getAppSettings().subscribe(
      (data) => {
        if (data) {
          this.appSetting = data as [];
        }
      },
      (error) => {}
    );
  }
}
