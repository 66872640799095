import { Component, OnInit, HostListener } from '@angular/core';
import { User } from 'src/app/models/user/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { NotificationsService } from 'src/app/services/notifications.service';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationsSearchModel } from 'src/app/models/notification/NotificationsSearchModel';
import { Notification } from 'src/app/models/notification/Notification';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  openMenu: boolean = false;
  currentUser: User = new User();
  isHome = false;
  userMenu = false;
  language = localStorage.getItem('language') || 'en';
  dataLoading = false;
  notifLoading = false;
  CheckKPIsAnswering = false;
  isEnterprise = false;
  pageNumber = 0;
  pageSize = 10;
  pageCount!: number;
  notificationMenu = false;
  notificationsResult: any = {
    unSeenNotificationsCount: 0,
    totalCount: 0,
  };
  notificationsList: Array<Notification> = [];
  NotificationsObject: NotificationsSearchModel =
    new NotificationsSearchModel();
  fullName: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private authenticationService: AuthenticationService,
    private notificationsService: NotificationsService,
    private signalRService: SignalRServiceService,
    private snackBar: MatSnackBar,
    private location: Location
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.signalRService.startConnection();

    this.authenticationService.currentUserSubject.subscribe((data: any) => {
      this.currentUser = data;
    });
    if (this.currentUser && this.currentUser.fullName) {
      this.getCurrentUserFullName();
      this.getNotifications(false);
      this.signalRService.changeNotificationCount(
        this,
        this.updateNotification
      );
    }
    router.events.subscribe((val) => {
      if (
        this.location.path() === '/home' ||
        this.location.path() === '' ||
        this.location.path() === '/projects'
      ) {
        this.isHome = true;
      } else {
        this.isHome = false;
      }
    });
  }

  ngOnInit() {
    console.log(this.currentUser);

    let currentUser: any = localStorage.getItem('currentUser');
    this.isEnterprise = JSON.parse(currentUser)?.userType == 2;
    // if (
    //   localStorage.getItem('KPIsAnswering') == 'false' &&
    //   JSON.parse(currentUser)?.userType == 2
    // ) {
    //   this.CheckKPIsAnswering = true;
    //   this.router.navigate(['/kpis']);
    // } else {
    //   this.route.snapshot.queryParams.returnUrl || '/profile';
    // }
    this.authenticationService.checkUserNameAfterUpdate.subscribe((data) => {
      if (data) {
        this.getCurrentUserFullName();
      }
    });
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    if (this.location.path() === '/home' || this.location.path() === '') {
      this.isHome = true;
    } else {
      this.isHome = false;
    }
  }

  getCurrentUserFullName() {
    this.authenticationService.getCurrentUserFullName().subscribe(
      (data) => {
        this.fullName = data;
      },
      (err) => {
        console.log(err);
      }
    );
    //
  }

  updateNotification(context: any) {
    context.getNotifications(true);
    context.openSnackBar('You have a new notification!');
  }

  openSnackBar(msg: any) {
    this.snackBar.open(msg, '✖', {
      duration: 10000,
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
    });
  }

  signOut() {
    this.signalRService.stopConnection();
    this.authenticationService.signOut().subscribe(
      (res) => {},
      (err) => {
        console.log(err);
      }
    );
    this.logout();
  }
  logout() {
    this.authenticationService.logout();
    if (this.isHome) {
      this.router.navigate(['/home']);
    } else {
      this.router.navigate(['/auth/login']);
    }
  }

  initName(name: any) {
    if (!name) {
      return '';
    }
    let initials = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || '') + (initials.pop() || '')
    ).toUpperCase();
    return initials;
  }

  changeLanguage() {
    if (this.language === 'en') {
      this.language = 'fr';
      localStorage.setItem('language', 'fr');
      this.translate.use('fr');
    } else {
      this.language = 'en';
      localStorage.setItem('language', 'en');
      this.translate.use('en');
    }
    window.location.reload();
  }

  ///////////////////////////////////////////////////////////////////////
  openNotificationPanel() {
    this.updateNotificationsSeenStatus();
    this.getNotifications(true, true);
    this.notificationMenu = !this.notificationMenu;
    this.userMenu = false;
  }

  getNextPage() {
    if (this.pageNumber < this.pageCount - 1) {
      this.pageNumber += 1;
      this.getNotifications(false);
    }
  }

  getNotifications(update: any, isOpen = false) {
    if (update) {
      this.dataLoading = true;
      this.pageNumber = 0;
      this.notificationsList = [];
    } else {
      this.notifLoading = true;
    }
    this.notifLoading = true;
    this.NotificationsObject.pageSize = this.pageSize;
    this.NotificationsObject.pageIndex = this.pageNumber;
    this.notificationsService
      .getNotifications(this.NotificationsObject)
      .subscribe(
        (result: any) => {
          this.notifLoading = false;
          this.notificationsResult = result;
          this.pageCount = Math.ceil(
            this.notificationsResult.totalCount / this.pageSize
          );
          this.notificationsList = this.notificationsList.concat(
            this.notificationsResult.pageData
          );
          console.log(isOpen);

          if (!isOpen) {
            this.notificationsResult.unSeenNotificationsCount =
              result.unSeenNotificationsCount;
          } else {
            this.notificationsResult.unSeenNotificationsCount = 0;
          }
          this.dataLoading = false;
        },
        (err) => {
          this.notifLoading = false;
          this.dataLoading = false;
          console.error(err);
        }
      );
  }

  updateNotificationsSeenStatus() {
    this.notificationsService.updateNotificationsSeenStatus().subscribe(
      (result) => {},
      (err) => {
        console.error(err);
      }
    );
  }

  @HostListener('window:keydown', ['$event']) handleKeyboardEvent(
    event: KeyboardEvent
  ) {
    if (event.key === 'Escape') {
      this.closeOpenedDrop();
    }
  }
  @HostListener('document:click', ['$event']) onDocumentClick(
    event: MouseEvent
  ) {
    if (event.target === document.getElementById('popOverlay')) {
      this.closeOpenedDrop();
    }
  }
  closeOpenedDrop() {
    this.userMenu = false;
    this.openMenu = false;
    this.notificationMenu = false;
    // this.updateNotificationsSeenStatus();
  }
}
