import {
  KpiAnswer,
  Question,
  Options,
  answer,
} from './../../models/lookups/kpis/Kpi';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-application-kpis',
  templateUrl: './application-kpis.component.html',
})
export class ApplicationKpisComponent implements OnInit {
  @Output() AnswerQues = new EventEmitter<any>();
  @Output() validAnswer = new EventEmitter<{}>();
  @Output() AnswerIndex = new EventEmitter<{}>();
  @Input() answerListing!: any;
  @Input() isSubmited = false;
  @Input() isReset = false;
  @Input() isRequired = false;
  @Input() isAnswered = false;
  @Input() appEditMode = false;
  @Input() question: any;
  answIndxOtherAnswer: any;
  options: any[] = [];
  multiSelectIds: any[] = [];
  qutionsAnswerd: any[] = [];
  qustionsIds: any[] = [];
  oneAnswer: answer = new answer();
  checkedItems: KpiAnswer[] = [];
  noChecked = false;
  otherChecked = false;
  clear = false;

  multiChoiceControl = new FormControl('', [Validators.required]);
  textareaControl = new FormControl('', [Validators.required]);
  numberControl = new FormControl('', [Validators.required]);
  textControl = new FormControl('', [Validators.required]);
  dateControl = new FormControl(null, [Validators.required]);
  timeControl = new FormControl('', [Validators.required]);
  onechoiceControl = new FormControl('', [Validators.required]);
  otherQuesControl = new FormControl('', [Validators.required]);
  constructor() {}

  ngOnInit(): void {

    console.log('KPIS', this.question);

    // this.options = this.qustions.map((q: any) => q);
    this.bindData();
    if (this.appEditMode) {
      //console.log('question' , this.question);
      // console.log('answerListing', this.answerListing);
      // console.log('question', this.question);
    }
  }

  bindData() {
    if (this.question?.answer) {
      if (this.question.questionTypeCode == 1) {
        this.question.options.forEach((option: any) => {
          if (
            this.question.answer.kpiQuestionValueIds.findIndex(
              (e: any) => e == option.questionOptionId
            ) != -1
          ) {
            this.onechoiceControl.setValue(option.questionOptionId);

            option.ischecked = true;
            this.radioListChange(this.question, option, true);
          }
        });
        if (this.question.isOtherAnswer) {
          this.otherQuesControl.setValue(this.question.answer?.text);
        }
      }

      if (this.question.questionTypeCode == 2) {
        this.question.options.forEach((option: any) => {
          if (
            this.question.answer.kpiQuestionValueIds.findIndex(
              (e: any) => e == option.questionOptionId
            ) != -1
          ) {
            this.multiSelectIds.push(option.questionOptionId);
            this.multiChoiceControl.setValue(this.multiSelectIds);
            this.oneAnswer.kpiQuestionValueIds = this.multiSelectIds;

            option.ischecked = true;
            if (option.isOther) {
              this.otherChecked = !this.otherChecked;
            }
            this.changeStatus(this.question, option, true);
          }
        });
        if (this.question.answer.isOtherAnswer) {
          this.otherQuesControl.setValue(this.question.answer?.text);
        }
      }

      if (this.question.questionTypeCode == 4) {
        if (this.question.answer?.text) {
          this.numberControl.setValue(this.question.answer?.text);
        }
        this.setAnswer(this.numberControl, this.question);
      }
      if (this.question.questionTypeCode == 3) {
        if (this.question.answer?.text) {
          this.numberControl.setValue(this.question.answer?.text);
        }

        this.setAnswer(this.numberControl, this.question);
      }
    }
  }

  checkOtherValue() {
    if (this.otherQuesControl.value) {
      if (this.oneAnswer.isOtherAnswer) {
        this.oneAnswer.text = this.otherQuesControl.value;
      }
      this.AnswerQues.emit(this.oneAnswer);
      this.validAnswer.emit(true);
    } else {
      this.validAnswer.emit(false);
    }
  }

  fixDuplicate(event: any) {
    console.log('event', event.value);
    this.oneAnswer.kpiQuestionValueIds = event.value;
  }

  changeStatus(question: any, option: any, event: any) {
    this.multiSelectIds.push(option.questionOptionId);
    this.oneAnswer.ischecked = true;

    // isother
    setTimeout(() => {
      if (option.isOther) {
        this.oneAnswer.otherAnswerId = option.questionOptionId;
        this.otherChecked = !this.otherChecked;
      }

      if (
        this.oneAnswer.kpiQuestionValueIds.includes(
          this.oneAnswer.otherAnswerId
        )
      ) {
        this.oneAnswer.isOtherAnswer = true;
        this.oneAnswer.text = this.otherQuesControl.value;
      } else {
        this.oneAnswer.isOtherAnswer = false;
        this.oneAnswer.text = '';
        this.oneAnswer.otherAnswerId = 0;
      }
      if (this.oneAnswer.kpiQuestionValueIds) {
        const set = new Set(this.oneAnswer.kpiQuestionValueIds);
        this.oneAnswer.kpiQuestionValueIds = [...set];
        this.AnswerQues.emit(this.oneAnswer);
        if (this.oneAnswer.isOtherAnswer == true && this.oneAnswer.text == '') {
          this.validAnswer.emit(false);
        } else {
          this.validAnswer.emit(true);
        }
      }
    }, 100);
    console.log('this.oneAnswer', this.oneAnswer, this.multiChoiceControl);

    if (option.ischecked) {
    } else {
      option.ischecked = false;
      this.oneAnswer.kpiQuestionValueIds =
        this.oneAnswer.kpiQuestionValueIds.filter(
          (id: any) => id !== option.questionOptionId
        );
      this.oneAnswer.isOtherAnswer = false;
      this.oneAnswer.text = '';
      this.oneAnswer.otherAnswerId = option.otherAnswerId;
      this.oneAnswer.ischecked = false;
      if (this.oneAnswer.kpiQuestionValueIds.length) {
        this.AnswerQues.emit(this.oneAnswer);
      }
    }
  }

  radioListChange(question: any, option: any, event: any) {
    this.oneAnswer.kpiQuestionValueIds = [];
    this.otherChecked = option.isOther;
    if (option.ischecked) {
      this.oneAnswer.isOtherAnswer = option.isOther;
      this.oneAnswer.kpiQuestionValueIds.push(option.questionOptionId);
      this.oneAnswer.text = this.otherQuesControl.value;
      this.oneAnswer.ischecked = true;
      if (option.isOther) {
        this.oneAnswer.otherAnswerId = option.questionOptionId;
      }

      if (this.oneAnswer.kpiQuestionValueIds) {
        this.AnswerQues.emit(this.oneAnswer);
        this.validAnswer.emit(true);
      }
    } else {
      option.ischecked = false;
      console.log(this.oneAnswer.kpiQuestionValueIds);

      this.oneAnswer.kpiQuestionValueIds =
        this.oneAnswer.kpiQuestionValueIds.filter(
          (id: any) => id !== option.questionOptionId
        );
      this.oneAnswer.isOtherAnswer = false;
      this.oneAnswer.text = '';
      this.oneAnswer.otherAnswerId = option.otherAnswerId;
      this.oneAnswer.ischecked = false;
      if (this.oneAnswer.kpiQuestionValueIds.length) {
        this.AnswerQues.emit(this.oneAnswer);
      }
    }
    console.log(this.oneAnswer);
  }

  setAnswer(control: any, question: any) {
    this.oneAnswer.kpiQuestionValueIds = [];
    if (control.value) {
      this.oneAnswer.isOtherAnswer = false;
      this.oneAnswer.kpiQuestionValueIds.push(
        question.applicationKPIQuestionId
      );
      this.oneAnswer.text = control.value;
      this.oneAnswer.ischecked = true;
      if (this.oneAnswer.kpiQuestionValueIds) {
        this.AnswerQues.emit(this.oneAnswer);
        this.validAnswer.emit(true);
      }
    } else {
      this.oneAnswer = new answer();
      delete this.oneAnswer.ischecked;
      this.validAnswer.emit(false);
      this.AnswerQues.emit(this.oneAnswer);
    }
  }
}
