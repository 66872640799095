import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

import { ExpertRegister } from '../models/auth/Expert-register';
import { Router } from '@angular/router';
import { User } from '../models/user/user';
import { RegistrationEnterpriseModel } from '../models/auth/RegistrationEnterpriseModel';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public authUser: any;
  checkUserNameAfterUpdate = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser')!)
    );
    this.currentUser = this.currentUserSubject.asObservable();
    window.addEventListener(
      'storage',
      () => {
        // debugger
        let adminUserStorage: any = localStorage.getItem('currentUser');
        const userToken = JSON.parse(adminUserStorage);
        //console.log(localStorage.getItem('currentUser'));
        if (
          (this.currentUserValue &&
            localStorage.getItem('currentUser') &&
            this.currentUserValue.accessToken !== userToken.accessToken) ||
          (this.currentUserValue && !localStorage.getItem('currentUser')) ||
          (!this.currentUserValue && localStorage.getItem('currentUser'))
        ) {
          window.location.reload();
        }
      },
      false
    );
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  login(email: any, password: any,deviceId:any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    // tslint:disable-next-line: max-line-length
    const code =
      encodeURIComponent(
        'qWAAq+lmFSIcn2NMVRfcwPHzHivAGWdfbrgoniWvbL/1DZ+izmy4vWDDFW6nLZNn'
      ) +
      '&client_secret=' +
      encodeURIComponent(
        'DPdU9mr4pHn3LYZu9jMfEvdP/fXCmsER5pABU4W4srApiFRGQHMvXEm/sBqcoVb1'
      );
    // tslint:disable-next-line: max-line-length

    const modal = {
      userName: email,
      password: password,
      deviceId:deviceId,
      secretId:
        'qWAAq+lmFSIcn2NMVRfcwHiPPkWaQx9ExCP5ndYYS/gDoA4Yyut7xdY9i5P16Vjn',
      secretKey:
        'DPdU9mr4pHn3LYZu9jMfEvdP/fXCmsER5pABU4W4srB+yFdJyOlDv5MOyME/HYGV',
    };
    // return this.http.post<any>(`${Config.loginUrl}`, 'username=' + email + '&password=' + password + '&grant_type=password&client_id=' + code, { headers })
    return this.http
      .post<any>(`${environment.loginUrl}`, modal, { headers })
      .pipe(
        map((user) => {
          if (user.accessToken) {
            console.log(user);

            this.currentUserSubject.next(user);
            this.authUser = user;
            this.getUserProfile();
          }
          return user;
        })
      );
  }
  getUserProfile() {
    this.router.navigate(['/profile']);
    let user = this.currentUserValue;
    localStorage.setItem('currentUser', JSON.stringify(user));
    // this.http.get(`${Config.apiUrl}/Authentication/UserProfile`).pipe(first())
    //     .subscribe((data: any) => {
    //         user.email = data.email;
    //         user.fullName = data.fullName;
    //         user.userType = data.userType;
    //         localStorage.setItem('currentUser', JSON.stringify(user));
    //         this.currentUserSubject.next(user);
    //         window.location.reload();
    //         return user;
    //     },
    //     error => {
    //         //
    //     });
  }
  checkUniqueEmail(email: any, userId: any) {
    let user = '';
    if (this.currentUserValue && this.currentUserValue.accessToken) {
      user = '&userId=0';
    }
    if (userId) {
      user = '&userId=' + userId;
    }
    return this.http.get(
      `${environment.apiUrl}/Expert/ValidateEmail?email=` + email + user
    );
  }
  checkUniqueEnterpriseEmail(email: any, userId: any) {
    let user = '';
    if (this.currentUserValue && this.currentUserValue.accessToken) {
      user = '&userId=0';
    }
    if (userId) {
      user = '&userId=' + userId;
    }
    return this.http.get(
      `${environment.apiUrl}/Enterprise/ValidateEmail?email=` + email + user
    );
  }
  checkUsername(username: any) {
    return this.http.get(
      `${environment.apiUrl}/account/ValidateUserName?filterKey=` + username
    );
  }
  registerExpert(user: ExpertRegister, files: any) {
    const formData = new FormData();
    files.forEach((file: any) => {
      formData.append('file[]', file, file.name);
    });
    formData.append('data', JSON.stringify(user));
    return this.http.post(`${environment.apiUrl}/expert/register`, formData);
  }

  forgetPassword(email: any) {
    return this.http.get(
      `${environment.apiUrl}/Authentication/ForgetPassword?email=` + email
    );
  }
  resetPassword(userId: any, code: any, newPassword: any) {
    return this.http.post(
      `${environment.apiUrl}/Authentication/ResetPassword`,
      {
        userId,
        token: code,
        newPassword,
      }
    );
  }
  checkResetPasswordLink(userId: any, code: any) {
    return this.http.post(`${environment.apiUrl}/Authentication/VerifyToken`, {
      userId,
      token: code,
    });
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    // this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')!));
    // this.currentUser = this.currentUserSubject.asObservable();
    this.currentUserSubject.next(null!);
    this.router.navigate(['/auth/login']);
  }
  signOut() {
    return this.http.post(`${environment.apiUrl}/Authentication/SignOut`, {});
  }
  confrimEmail(userId: any, code: any) {
    return this.http.get(
      `${environment.apiUrl}/account/ConfirmEmail?userId=` +
        userId +
        '&code=' +
        encodeURIComponent(code)
    );
  }
  registerEnterprise(user: RegistrationEnterpriseModel, files: any[]) {
    const formData = new FormData();
    files.forEach((file) => {
      if (!file.attachmentId) {
        // send new files
        formData.append('file[]', file, file.name);
      }
    });
    formData.append('data', JSON.stringify(user));
    if (user.id) {
      return this.http.post(
        `${environment.apiUrl}/enterprise/EditRegistrationRequest`,
        formData
      );
    } else {
      return this.http.post(
        `${environment.apiUrl}/enterprise/Register`,
        formData
      );
    }
  }
  getEnterpriseEmail(userId: any) {
    return this.http.get(
      `${environment.apiUrl}/Enterprise/GetEnterpriseEmail?userId=` + userId
    );
  }

  getCurrentUserFullName() {
    return this.http.get(
      `${environment.apiUrl}/Authentication/GetCurrentUserFullName`
    );
  }
  checkEnterpriseKPIsAnswering() {
    return this.http.get(
      `${environment.apiUrl}/Enterprise/CheckEnterpriseKPIsAnswering`
    );
  }
  checkFilledApplication() {
    return this.http.get(
      `${environment.apiUrl}/Enterprise/CheckFilledApplication`
    );
  }
  RefeshUserToken() {
    return this.http
      .post<User>(`${environment.apiUrl}/Authentication/RefreshToken`, {})
      .pipe(
        map((user) => {
          if (user) {
            this.currentUserSubject.next(user);
            localStorage.setItem('currentUser', JSON.stringify(user));
            if (this.router.url !== '/profile') {
              location.reload();
            }
          } else {
            this.logout();
          }
        })
      );
  }
}
